import React from 'react';
import PresetSelector from '../PresetSelector';
import CopyAllButton from '../CopyAllButton';
import ExportDocxButton from '../ExportDocxButton';
import OCRButton from '../OCRButton';
import MessageButton from '../MessageButton';

function NoteEditorHeader({ note, updateNote, currentPreset, handlePresetChange, presetSections }) {
    return (
        <div className="mb-4 flex flex-wrap gap-2 items-center justify-between">
            <PresetSelector currentPreset={currentPreset} onPresetChange={handlePresetChange} />
            <div className="flex flex-wrap gap-2">
                <OCRButton note={note} updateNote={updateNote} />
                <MessageButton note={note} currentPreset={currentPreset} />
                <CopyAllButton note={note} currentPreset={currentPreset} presetSections={presetSections} />
                <ExportDocxButton note={note} currentPreset={currentPreset} presetSections={presetSections} />
            </div>
        </div>
    );
}

export default NoteEditorHeader;