import { isTomorrow } from 'date-fns';

export const createSignature = (notePreset, settings) => {
    let signature = '';

    if (notePreset === "ID Consult") {
        signature = "\nDiscussed recommendations with Primary Team.\n\n";
    }

    //Timed naming of person you are signing out to.
    if (settings.signoutDate && settings.signoutPerson) {
        if (isTomorrow(settings.signoutDate)) {
            signature += `Dr. ${settings.signoutPerson} will assume care of service tomorrow.\n\n`;
        }
    }

    signature += `Thank you for involving us in the care of your patient. Please reach out to the Infectious Disease consultation team for any questions or concerns.

Terry Marryshow, MD
Infectious Disease Attending
Pager # 401-582-5546 or reachable via AMS Connect`;

    if (notePreset === "ID Consult") {
        signature += "\n\nBilling: 99223";
    } else if (notePreset === "ID Follow Up") {
        signature += "\n\nBilling: 99233";
    }

    return signature;
};