import React from 'react';
import headerLogo from '../images/header_mascot.png';
import SettingsButton from './SettingsButton';

function Header({ onLogout, collapseHeader }) {
    return (
        <header className={`fixed top-0 left-0 right-0 z-30 ${collapseHeader ? 'h-12' : 'h-20'} transition-all duration-300`}>
            <div className={`bg-[#121212] shadow-md ${collapseHeader ? 'p-1' : 'p-2'} h-full`}>
                <div className="container mx-auto flex items-center justify-between h-full">
                    {/* Center content */}
                    <div className="flex-grow flex items-center justify-center">
                        {!collapseHeader && (
                            <div className="flex items-center">
                                <img 
                                    src={headerLogo} 
                                    title="Scribbles" 
                                    alt="Scribbles"
                                    className="w-14 h-14 sm:w-16 sm:h-16 md:w-18 md:h-18 lg:w-20 lg:h-20 mr-2 sm:mr-3 object-contain" 
                                />
                                <h1
                                    className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold matemasie-regular text-amber-300"
                                    style={{
                                        textShadow: '2px 2px 4px rgba(0,0,0,0.8), 0 0 10px rgba(0,0,0,0.7), 1px 1px 2px rgba(0,0,0,0.9)'
                                    }}
                                >
                                    Honey-Scribe
                                </h1>
                            </div>
                        )}
                    </div>
                    
                    {/* Settings and Logout buttons */}
                    <div className="absolute right-2 sm:right-4 md:right-6 lg:right-8 flex items-center space-x-2">
                        <SettingsButton />
                        <button
                            onClick={onLogout}
                            className={`w-8 h-8 sm:w-10 sm:h-10 rounded
                                bg-red-800 text-white hover:bg-red-900 transition duration-150 ease-in-out flex items-center justify-center`}
                            title="Logout"
                        >
                            <span className="material-symbols-outlined text-lg sm:text-xl">power_settings_new</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`bg-gradient-to-b from-[#121212] to-transparent ${collapseHeader ? 'h-2' : 'h-4'}`}></div>
        </header>
    );
}

export default Header;
