import React from 'react';
import AntimicrobialView from './AntimicrobialView';
import BetaLactamAllergyView from './BetaLactamAllergyView';

function Insights({ note }) {
    return (
        <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white">Insights for {note.title}</h2>
            <BetaLactamAllergyView note={note} />
            <AntimicrobialView note={note} />
        </div>
    );
}

export default React.memo(Insights); 