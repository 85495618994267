import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import dotPhrases from './data/dotPhrases';
import { sectionHeadings } from './utils/noteFormatUtils';

const splitIntoProblems = (text) => {
  const lines = text.split('\n');
  const problems = [];
  let currentProblem = [];

  lines.forEach((line) => {
    if (line.startsWith('#')) {
      if (currentProblem.length > 0) {
        problems.push(currentProblem);
      }
      currentProblem = [line];
    } else if (line.trim() !== '') {
      currentProblem.push(line);
    }
  });

  if (currentProblem.length > 0) {
    problems.push(currentProblem);
  }

  return problems;
};

const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
};

const DotPhraseDropdown = forwardRef(({ value, section, onChange, id, isSticky, isRecording, onFocus, onBlur }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [matchingPhrases, setMatchingPhrases] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [cursorPosition, setCursorPosition] = useState(0);
    const dropdownRef = useRef(null);
    const textareaRef = useRef(null);
    const containerRef = useRef(null);
    const [textMetrics, setTextMetrics] = useState({ charWidth: 8, lineHeight: 24 });

    const updateMatchingPhrases = useCallback(() => {
        const words = value.slice(0, cursorPosition).split(/\s+/);
        const lastWord = words[words.length - 1];

        if (/^\.[a-zA-Z]+/.test(lastWord)) {
            const matches = Object.keys(dotPhrases).filter(key => key.startsWith(lastWord));
            setMatchingPhrases(matches);
            setIsVisible(matches.length > 0);
            setSelectedIndex(0);
        } else {
            setIsVisible(false);
        }
    }, [value, cursorPosition]);

    useEffect(() => {
        updateMatchingPhrases();
    }, [value, cursorPosition, updateMatchingPhrases]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = useCallback((phrase) => {
        const replacement = dotPhrases[phrase];
        const beforeCursor = value.slice(0, cursorPosition);
        const afterCursor = value.slice(cursorPosition);

        const lastWordMatch = beforeCursor.match(/\.[a-zA-Z]\w*$/);
        const lastWord = lastWordMatch ? lastWordMatch[0] : '';
        const newContent = beforeCursor.slice(0, -lastWord.length) + replacement + afterCursor;

        onChange(newContent);

        const newCursorPosition = cursorPosition - lastWord.length + replacement.length;
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
                textareaRef.current.focus();
            }
        }, 0);

        setIsVisible(false);
    }, [value, cursorPosition, onChange]);

    const handleKeyDown = (e) => {
        if (!isVisible) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex + 1) % matchingPhrases.length);
                break;
            case 'ArrowUp':
                e.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex - 1 + matchingPhrases.length) % matchingPhrases.length);
                break;
            case 'Enter':
                e.preventDefault();
                if (matchingPhrases[selectedIndex]) {
                    handleSelect(matchingPhrases[selectedIndex]);
                }
                break;
            case 'Escape':
                setIsVisible(false);
                break;
            default:
                break;
        }
    };

    const getDropdownPosition = useCallback(() => {
        if (textareaRef.current && containerRef.current) {
            const textareaRect = textareaRef.current.getBoundingClientRect();
            const containerRect = containerRef.current.getBoundingClientRect();

            const dropdownWidth = Math.max(200, textareaRect.width * 0.5);
            const leftPosition = Math.min(
                textareaRect.left - containerRect.left,
                containerRect.width - dropdownWidth
            );

            return {
                top: textareaRect.bottom - containerRect.top,
                left: leftPosition,
                width: dropdownWidth,
            };
        }
        return {};
    }, []);

    const [dropdownPosition, setDropdownPosition] = useState({});

    useEffect(() => {
        const updateDropdownPosition = () => {
            setDropdownPosition(getDropdownPosition());
        };

        updateDropdownPosition();
        window.addEventListener('resize', updateDropdownPosition);
        return () => window.removeEventListener('resize', updateDropdownPosition);
    }, [getDropdownPosition]);

    const autoResize = useCallback(() => {
        if (textareaRef.current) {
            // Store the current scroll position
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            
            textareaRef.current.style.height = 'auto';
            if (isSticky) {
                textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`;
            } else {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
            
            // Recalculate text metrics
            const style = window.getComputedStyle(textareaRef.current);
            const font = `${style.fontSize} ${style.fontFamily}`;
            const fontSize = parseInt(style.fontSize);
            const lineHeight = parseInt(style.lineHeight);
            
            const testDiv = document.createElement('div');
            testDiv.style.font = font;
            testDiv.style.visibility = 'hidden';
            testDiv.style.position = 'absolute';
            testDiv.textContent = '#';
            document.body.appendChild(testDiv);
            
            const actualHeight = testDiv.offsetHeight;
            document.body.removeChild(testDiv);
            
            setTextMetrics({
                charWidth: getTextWidth('#', font),
                lineHeight: lineHeight || fontSize * 1.2,
                fontSize: fontSize,
                actualHeight: actualHeight
            });
            
            // Restore the scroll position
            window.scrollTo(0, scrollTop);
        }
        setDropdownPosition(getDropdownPosition());
    }, [getDropdownPosition, isSticky]);

    //Resizes textbox when the window resizes and on value change
    useEffect(() => {
        // Initial resize
        autoResize();

        // Debounce the resize handler to prevent too many calculations
        let resizeTimeout;
        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                autoResize();
            }, 100); // 100ms debounce
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function
        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(resizeTimeout);
        };
    }, [value, autoResize]); // Add value as a dependency to ensure resize on content changes

    useEffect(() => {
        if (textareaRef.current) {
            const style = window.getComputedStyle(textareaRef.current);
            const font = `${style.fontSize} ${style.fontFamily}`;
            const fontSize = parseInt(style.fontSize);
            const lineHeight = parseInt(style.lineHeight);
            
            // Create a test div to measure actual text rendering
            const testDiv = document.createElement('div');
            testDiv.style.font = font;
            testDiv.style.visibility = 'hidden';
            testDiv.style.position = 'absolute';
            testDiv.textContent = '#';
            document.body.appendChild(testDiv);
            
            const actualHeight = testDiv.offsetHeight;
            document.body.removeChild(testDiv);
            
            setTextMetrics({
                charWidth: getTextWidth('#', font),
                lineHeight: lineHeight || fontSize * 1.2,
                fontSize: fontSize,
                actualHeight: actualHeight
            });
        }
    }, []);

    // When copying, we'll get the problem content without the header
    const handleCopy = (e, problem) => {
        e.preventDefault();
        // Filter out the header and specific lines before joining
        const contentWithoutHeader = problem
            .slice(1) // Remove header
            .filter(line => 
                !line.includes("Assessment and Plan:") && 
                !line.includes("Assessment and Recommendations")
            )
            .join('\n');
        navigator.clipboard.writeText(contentWithoutHeader);
        const button = e.currentTarget;
        button.textContent = 'done';
        setTimeout(() => {
            button.textContent = 'content_copy';
        }, 1000);
    };

    return (
        <div ref={containerRef} className="relative w-full group">
            <div className="relative">
                <textarea
                    ref={(element) => {
                        textareaRef.current = element;
                        if (typeof ref === 'function') {
                            ref(element);
                        } else if (ref) {
                            ref.current = element;
                        }
                    }}
                    id={id}
                    value={value}
                    onChange={(e) => {
                        onChange(e.target.value);
                        setCursorPosition(e.target.selectionStart);
                        autoResize();
                    }}
                    onKeyDown={handleKeyDown}
                    onSelect={(e) => setCursorPosition(e.target.selectionStart)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={`block w-full rounded-md bg-[#242424] border border-[#323232] focus:border-[#90caf9] text-[#90caf9] transition duration-150 ease-in-out px-3 py-2 whitespace-pre-wrap ${
                        isRecording ? 'shadow-[0_0_15px_rgba(239,68,68,0.7)]' : 'shadow-none'
                    } ${
                        isSticky ? 'max-h-[200px] overflow-y-auto' : ''
                    }`}
                    rows="3"
                    style={{ 
                        resize: 'none', 
                        overflow: isSticky ? 'auto' : 'hidden',
                        color: '#242424',
                        caretColor: 'white',
                        backgroundColor: 'transparent'
                    }}
                    onInput={autoResize}
                />
                <div 
                    className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none px-3 py-2 whitespace-pre-wrap"
                    style={{
                        color: 'white',
                        overflow: 'hidden'
                    }}
                >
                    {value.split('\n').map((line, index) => {
                        // If line starts with #, make the whole line blue
                        if (line.startsWith('#')) {
                            return (
                                <div key={index} style={{ color: '#90caf9' }}>
                                    {line || ' '}
                                </div>
                            );
                        }

                        // For other lines, check if they start with any section heading
                        const matchedHeading = Object.values(sectionHeadings)
                            .flat()
                            .find(heading => 
                                line.toLowerCase().startsWith(heading.toLowerCase())
                            );

                        if (matchedHeading) {
                            const remainingText = line.slice(matchedHeading.length);
                            return (
                                <div key={index}>
                                    <span style={{ color: '#90caf9' }}>{matchedHeading}</span>
                                    <span style={{ color: 'white' }}>{remainingText}</span>
                                </div>
                            );
                        }

                        // Default case: regular white text
                        return (
                            <div key={index} style={{ color: 'white' }}>
                                {line || ' '}
                            </div>
                        );
                    })}
                </div>
                <div className="absolute top-0 left-0 pointer-events-none w-full">
                    {value.split('\n').map((line, index) => {
                        if (line.startsWith('#') && textareaRef.current) {
                            const problem = splitIntoProblems(value).find(p => p[0] === line);
                            const style = window.getComputedStyle(textareaRef.current);
                            const font = `${style.fontSize} ${style.fontFamily}`;
                            const lineWidth = getTextWidth(line, font);
                            
                            // Calculate the vertical position based on all previous lines
                            const previousLines = value.split('\n').slice(0, index);
                            const verticalOffset = previousLines.reduce((acc, line) => {
                                // Add line height for each previous line
                                return acc + textMetrics.lineHeight;
                            }, 0);
                            
                            return (
                                <div 
                                    key={index} 
                                    className="relative flex items-center"
                                    style={{
                                        height: `${textMetrics.lineHeight}px`,
                                        transform: `translateY(${verticalOffset + 8}px)`,
                                        position: 'absolute',
                                        left: `${lineWidth + 8}px`,
                                        width: 'fit-content'
                                    }}
                                >
                                    <button
                                        className="material-symbols-outlined opacity-0 group-hover:opacity-100 hover:opacity-100 pointer-events-auto cursor-pointer text-gray-400 hover:text-white transition-opacity duration-200 text-[16px] leading-none ml-2"
                                        onClick={(e) => handleCopy(e, problem)}
                                    >
                                        content_copy
                                    </button>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
            {isVisible && (
                <div
                    ref={dropdownRef}
                    className="absolute z-10 bg-[#242424] border border-[#404040] rounded-md shadow-lg overflow-hidden"
                    style={{
                        top: `${dropdownPosition.top}px`,
                        left: `${dropdownPosition.left}px`,
                        width: `${dropdownPosition.width}px`,
                        maxHeight: '200px',
                        overflowY: 'auto'
                    }}
                >
                    {matchingPhrases.map((phrase, index) => (
                        <div
                            key={phrase}
                            className={`px-4 py-2 cursor-pointer transition-colors duration-150 ease-in-out ${index === selectedIndex
                                ? 'bg-[#1976d2] text-white'
                                : 'text-[#e0e0e0] hover:bg-[#2a2a2a]'
                                }`}
                            onClick={() => handleSelect(phrase)}
                        >
                            <span className="font-medium">{phrase}</span>
                            <span className="block text-xs text-[#ababab] truncate">
                                {dotPhrases[phrase].substring(0, 40)}...
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default DotPhraseDropdown;
