import React, { useState } from 'react';
import DotPhraseDropdown from '../DotPhraseDropdown';
import AudioTranscription from '../AudioTranscription';
import BillingButton from '../BillingButton';

export const sectionLabels = {
    consultInfo: 'Consult Information',
    hpi: 'History of Present Illness',
    medicalHistory: 'Medical History | Surgical History | Allergies',
    socialHistory: 'Social History',
    antimicrobials: 'Antimicrobials',
    physicalExam: 'Physical Examination',
    labs: 'Labs | Microbiology',
    imaging: 'Radiology | Cardiology',
    recommendations: 'Assessment and Recommendations'
};

const defaultSectionText = {
    consultInfo: 'Consulting Physician: \nConsulting Service: \nReason for Consultation: ',
    medicalHistory: 'Medical History:\n\nSurgical History:\n\nAntimicrobial Allergies:',
    socialHistory: 'Social History:\n',
    antimicrobials: 'Antimicrobials:\n',
    labs: 'Labs:\n\nMicrobiology:\n'
};

function NoteEditorSections({
    note,
    currentPreset,
    updateNote,
    summarizeNote,
    isLoading,
    handleCopySection,
    handleTranscriptionUpdate,
    startRecordingSession,
    endRecordingSession,
    presetSections,
    actionSuccess,
    refs,
    pinnedSection,
    setPinnedSection,
    collapseHeader
}) {
    const headerHeight = collapseHeader ? '48px' : '96px';
    const [activeRecordingSection, setActiveRecordingSection] = useState(null);
    const [activeSection, setActiveSection] = useState(null);

    const handleSectionFocus = (section) => {
        setActiveSection(section);
    };

    const renderSection = (section, label) => (
        <div 
            className={`mb-8 ${
                pinnedSection === section 
                    ? 'sticky z-10 bg-[#1e1e1e] bg-opacity-70 p-4 shadow-lg border border-blue-500 rounded-lg backdrop-filter backdrop-blur-sm' 
                    : ''
            }`} 
            style={pinnedSection === section ? { top: headerHeight, bottom: 0 } : {}}
            key={section}
        >
            <div className="flex items-center justify-between mb-1">
                <label htmlFor={section} className="block text-sm font-medium text-[#e0e0e0]">{label}:</label>
                <button
                    onClick={() => setPinnedSection(pinnedSection === section ? null : section)}
                    className={`p-1 rounded transition-colors duration-200 ${
                        pinnedSection === section 
                            ? 'text-blue-500 bg-[#1976d2] bg-opacity-20' 
                            : 'text-[#ababab] hover:text-[#e0e0e0]'
                    }`}
                    title={pinnedSection === section ? "Unpin section" : "Pin section"}
                >
                    <span className={`material-icons text-base transition-transform duration-200 ${
                        pinnedSection === section ? 'transform rotate-90' : ''
                    }`}>push_pin</span>
                </button>
            </div>
            <div className="relative">
                <DotPhraseDropdown
                    section={section}
                    id={section}
                    value={note[section] || defaultSectionText[section] || ""}
                    onChange={(newValue) => updateNote(section, newValue)}
                    ref={refs[section]}
                    isSticky={pinnedSection === section}
                    isRecording={activeRecordingSection === section}
                    onFocus={() => handleSectionFocus(section)}
                    onBlur={() => handleSectionFocus(null)}
                />
            </div>
            {pinnedSection !== section && (
                <div className="mt-2">
                    <div className="flex justify-between">
                        <div>
                            {section === 'recommendations' && (
                                <BillingButton 
                                    assessment={note[section] || ''} 
                                    updateNote={updateNote}
                                />
                            )}
                        </div>
                        <div className="flex flex-wrap items-center gap-2">
                            <AudioTranscription
                                onTranscriptionUpdate={(transcript, isFinal) => handleTranscriptionUpdate(section, transcript, isFinal)}
                                onRecordingStart={() => handleRecordingStart(section)}
                                onRecordingEnd={() => handleRecordingEnd(section)}
                                isActiveSection={activeSection === section || activeRecordingSection === section}
                                buttonClassName="flex items-center justify-center px-2 py-1 rounded-md border border-[#323232]"
                                iconClassName="material-icons text-white text-base"
                                labelClassName="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline"
                            />
                            <button
                                type="button"
                                onClick={() => handleCopySection(section)}
                                className="flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] transition duration-150 ease-in-out"
                                title="Copy"
                            >
                                <span className="material-symbols-outlined text-white text-base">
                                    {actionSuccess[`${section}-copy`] ? 'check_circle' : 'content_copy'}
                                </span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">Copy</span>
                            </button>
                            <button
                                type="button"
                                onClick={() => summarizeNote(section)}
                                disabled={isLoading[section]}
                                className={`flex items-center justify-center px-2 py-1 rounded-md ${isLoading[section] ? 'bg-[#323232]' : 'bg-[#2a2a2a] hover:bg-[#323232]'} border border-[#323232] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out`}
                                title="Summarize"
                            >
                                {isLoading[section] ? (
                                    <span className="material-icons text-white text-base animate-spin">hourglass_empty</span>
                                ) : (
                                    <span className="material-icons text-white text-base">auto_fix_high</span>
                                )}
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">Format</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const handleRecordingStart = (section) => {
        setActiveRecordingSection(section);
        startRecordingSession(section);
    };

    const handleRecordingEnd = (section) => {
        setActiveRecordingSection(null);
        endRecordingSession(section);
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
            <div>
                <label htmlFor="noteTitle" className="block text-sm font-medium text-[#e0e0e0] mb-1">Patient Name:</label>
                <input
                    type="text"
                    id="noteTitle"
                    value={note.title === "Untitled Note" ? "" : note.title || ""}
                    onChange={(e) => updateNote('title', e.target.value || "Untitled Note")}
                    placeholder={note.title === "Untitled Note" ? "Untitled Note" : ""}
                    className="block w-full rounded-md bg-[#242424] border border-[#323232] text-white focus:border-[#90caf9] focus:ring-0 transition duration-150 ease-in-out shadow-sm px-3 py-2 whitespace-pre-wrap"
                    autoComplete="off"
                />
            </div>
            {presetSections[currentPreset].map(section => renderSection(section, sectionLabels[section]))}
        </form>
    );
}

export default NoteEditorSections;
