import React, { useState, useEffect, useRef } from 'react';

function Sidebar({
    notes,
    selectedNote,
    handleNewNote,
    newNoteCreated,
    handleNoteSelect,
    handleDeleteNote,
    setShowDeleteAllConfirmation,
    reorderNotes,
    isPanelCollapsed,
    setIsPanelCollapsed,
    handleSignout
}) {
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [draggedNote, setDraggedNote] = useState(null);
    const [draggedOverNote, setDraggedOverNote] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const notesListRef = useRef(null);
    const sidebarRef = useRef(null);
    const [isNewNoteAnimating, setIsNewNoteAnimating] = useState(false);
    const [presetFilter, setPresetFilter] = useState('all');
    const [presetCounts, setPresetCounts] = useState({ all: 0, outpatient: 0, inpatient: 0 });

    useEffect(() => {
        if (newNoteCreated && notesListRef.current) {
            notesListRef.current.scrollTop = notesListRef.current.scrollHeight;
        }

        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !isPanelCollapsed && !isSearchFocused) {
                setIsPanelCollapsed(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [newNoteCreated, notes, isPanelCollapsed, isSearchFocused, setIsPanelCollapsed]);

    useEffect(() => {
        const counts = {
            all: notes.length,
            outpatient: notes.filter(note => note.preset === 'Outpatient').length,
            inpatient: notes.filter(note => note.preset !== 'Outpatient').length
        };
        setPresetCounts(counts);

        const savedFilter = localStorage.getItem('presetFilter') || 'all';
        setPresetFilter(savedFilter);
    }, [notes]);

    const handleDeleteConfirmation = (noteId) => {
        setDeleteConfirmation(noteId);
    };

    const handleDragStart = (e, note) => {
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', note.id);
        setDraggedNote(note);
        setTimeout(() => {
            e.target.style.opacity = '0.5';
        }, 0);
    };

    const handleDragOver = (e, note) => {
        e.preventDefault();
        setDraggedOverNote(note);
    };

    const handleDragEnd = (e) => {
        e.target.style.opacity = '1';
        setDraggedNote(null);
        setDraggedOverNote(null);
    };

    const handleDrop = (e, targetNote) => {
        e.preventDefault();
        const draggedNoteId = e.dataTransfer.getData('text');
        const draggedIndex = notes.findIndex(note => note.id.toString() === draggedNoteId);
        const targetIndex = notes.findIndex(note => note.id === targetNote.id);

        if (draggedIndex !== targetIndex) {
            const newNotes = Array.from(notes);
            const [reorderedItem] = newNotes.splice(draggedIndex, 1);
            newNotes.splice(targetIndex, 0, reorderedItem);
            reorderNotes(newNotes);
        }
    };

    const getStyles = (note) => {
        if (draggedNote && draggedNote.id === note.id) {
            return "opacity-50";
        }
        if (draggedOverNote && draggedOverNote.id === note.id) {
            const draggedIndex = notes.findIndex(n => n.id === draggedNote.id);
            const targetIndex = notes.findIndex(n => n.id === note.id);
            if (draggedIndex < targetIndex) {
                return "border-b-2 border-blue-500";
            } else {
                return "border-t-2 border-blue-500";
            }
        }
        return "";
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const filteredNotes = notes.filter(note =>
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (presetFilter === 'all' || 
         (presetFilter === 'outpatient' && note.preset === 'Outpatient') ||
         (presetFilter === 'inpatient' && note.preset !== 'Outpatient'))
    );

    const handleNewNoteClick = () => {
        setIsNewNoteAnimating(true);
        handleNewNote();
        setTimeout(() => setIsNewNoteAnimating(false), 200); // Reset after animation
    };

    const handlePresetFilterChange = (filter) => {
        setPresetFilter(filter);
        localStorage.setItem('presetFilter', filter);
    };

    return (
        <div
            ref={sidebarRef}
            className={`
        fixed top-0 left-0 h-full bg-[#121212] bg-opacity-80 backdrop-filter backdrop-blur-sm shadow-lg
        transition-all duration-300 border-r border-[#2a2a2a] flex flex-col z-50
        ${isPanelCollapsed && !isSearchFocused ? 'w-16' : 'w-64'}
    `}>
            <div className="p-4 flex-shrink-0">
                <div className="flex justify-between items-center mb-4">
                    {!isPanelCollapsed && <h2 className="text-xl font-semibold text-gray-100">Saved Notes</h2>}
                    <button
                        onClick={() => setIsPanelCollapsed(!isPanelCollapsed)}
                        className="text-[#ababab] hover:bg-[#2a2a2a] hover:bg-opacity-50 p-2 rounded transition duration-150 ease-in-out"
                    >
                        <span className="material-icons">
                            {isPanelCollapsed ? 'chevron_right' : 'chevron_left'}
                        </span>
                    </button>
                </div>
                {!isPanelCollapsed && (
                    <div className="space-y-2 mb-4">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search notes..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onFocus={() => setIsSearchFocused(true)}
                                onBlur={() => setIsSearchFocused(false)}
                                className="w-full p-2 pr-8 bg-[#2a2a2a] border-[#323232] bg-opacity-50 text-white rounded border border-[#323232] focus:border-blue-500 focus:ring focus:ring-[#1976d2] focus:ring-opacity-50"
                            />
                            {searchTerm && (
                                <button
                                    onClick={handleClearSearch}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#ababab] hover:text-white"
                                >
                                    <span className="material-icons text-sm">close</span>
                                </button>
                            )}
                        </div>
                        <div className="flex space-x-1 text-xs">
                            <button
                                onClick={() => handlePresetFilterChange('all')}
                                className={`flex-1 px-2 py-1 rounded transition-colors ${
                                    presetFilter === 'all' 
                                        ? 'bg-[#1976d2] text-white' 
                                        : 'bg-[#2a2a2a] bg-opacity-50 text-[#e0e0e0] hover:bg-opacity-75'
                                }`}
                            >
                                All {presetCounts.all > 0 && `(${presetCounts.all})`}
                            </button>
                            <button
                                onClick={() => handlePresetFilterChange('outpatient')}
                                className={`flex-1 px-2 py-1 rounded transition-colors ${
                                    presetFilter === 'outpatient'
                                        ? 'bg-[#1976d2] text-white'
                                        : 'bg-[#2a2a2a] bg-opacity-50 text-[#e0e0e0] hover:bg-opacity-75'
                                }`}
                            >
                                Outpatient {presetCounts.outpatient > 0 && `(${presetCounts.outpatient})`}
                            </button>
                            <button
                                onClick={() => handlePresetFilterChange('inpatient')}
                                className={`flex-1 px-2 py-1 rounded transition-colors ${
                                    presetFilter === 'inpatient'
                                        ? 'bg-[#1976d2] text-white'
                                        : 'bg-[#2a2a2a] bg-opacity-50 text-[#e0e0e0] hover:bg-opacity-75'
                                }`}
                            >
                                Inpatient {presetCounts.inpatient > 0 && `(${presetCounts.inpatient})`}
                            </button>
                        </div>
                    </div>
                )}
                {isPanelCollapsed ? (
                    <button
                        onClick={handleNewNoteClick}
                        title="New Note"
                        className={`w-full flex items-center justify-center p-2 bg-[#1976d2] hover:bg-[#2196f3] rounded transition duration-150 ease-in-out shadow-md text-white ${
                            isNewNoteAnimating ? 'animate-click' : ''
                        }`}
                    >
                        <span className="material-symbols-outlined">note_stack_add</span>
                    </button>
                ) : (
                    notes.length > 0 ? (
                        <div className="flex space-x-2">
                            <button
                                onClick={handleSignout}
                                title="Generate Signout"
                                className="flex-1 flex items-center justify-center p-2 bg-[#00c853] hover:bg-[#00e676] rounded transition duration-150 ease-in-out shadow-md text-white"
                            >
                                <span className="material-icons">assignment_ind</span>
                                Signout
                            </button>
                            <button
                                onClick={handleNewNoteClick}
                                title="New Note"
                                className={`flex-1 flex items-center justify-center p-2 bg-[#1976d2] hover:bg-[#2196f3] rounded transition duration-150 ease-in-out shadow-md text-white ${
                                    isNewNoteAnimating ? 'animate-click' : ''
                                }`}
                            >
                                <span className="material-symbols-outlined">note_stack_add</span>
                                New
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleNewNoteClick}
                            title="New Note"
                            className={`w-full flex items-center justify-center p-2 bg-[#1976d2] hover:bg-[#2196f3] rounded transition duration-150 ease-in-out shadow-md text-white ${
                                isNewNoteAnimating ? 'animate-click' : ''
                            }`}
                        >
                            <span className="material-symbols-outlined">note_stack_add</span>
                            New Note
                        </button>
                    )
                )}
            </div>
            <div ref={notesListRef} className="flex-grow overflow-y-auto">
                {!isPanelCollapsed && (
                    <ul className="space-y-2 px-4">
                        {filteredNotes.map((note) => (
                            <li
                                key={note.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, note)}
                                onDragOver={(e) => handleDragOver(e, note)}
                                onDragEnd={handleDragEnd}
                                onDrop={(e) => handleDrop(e, note)}
                                className={`p-2 rounded cursor-pointer hover:bg-[#2a2a2a] hover:bg-opacity-50 transition duration-150 ease-in-out ${selectedNote && selectedNote.id === note.id ? 'bg-[#2a2a2a] bg-opacity-50 shadow-md' : ''
                                    } ${getStyles(note)}`}
                                onClick={() => handleNoteSelect(note)}
                            >
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center min-w-0 flex-grow">
                                        <span className="mr-2 text-xs font-semibold bg-[#2a2a2a] text-white px-1 rounded flex-shrink-0">
                                            {note.preset === 'Outpatient' ? 'OP' :
                                                note.preset === 'ID Consult' ? 'NEW' :
                                                    note.preset === 'ID Follow Up' ? 'F/U' : ''}
                                        </span>
                                        <span className="truncate overflow-hidden text-ellipsis flex-grow text-[#e0e0e0]">
                                            {note.title || 'Untitled Note'}
                                        </span>
                                    </div>
                                    <div className="flex-shrink-0 ml-2">
                                        {deleteConfirmation === note.id ? (
                                            <div className="flex items-center space-x-2">
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteNote(note.id);
                                                    }}
                                                    className="text-red-400 hover:text-red-300 transition duration-150 ease-in-out"
                                                >
                                                    <span className="material-icons text-sm">check</span>
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDeleteConfirmation(null);
                                                    }}
                                                    className="text-[#ababab] hover:text-[#e0e0e0] transition duration-150 ease-in-out"
                                                >
                                                    <span className="material-icons text-sm">close</span>
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteConfirmation(note.id);
                                                }}
                                                className="text-red-400 hover:text-red-300 transition duration-150 ease-in-out"
                                            >
                                                    <span className="material-symbols-outlined text-sm">delete</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {!isPanelCollapsed && (
                <div className="p-4 border-t border-[#2a2a2a] flex-shrink-0">
                    <button
                        onClick={() => setShowDeleteAllConfirmation(true)}
                        className="w-full flex items-center justify-center p-2 bg-[#b71c1c] hover:bg-[#d32f2f] rounded transition duration-150 ease-in-out shadow-md text-white"
                    >
                        <span className="material-icons mr-2">delete_forever</span>
                        Delete All
                    </button>
                </div>
            )}
        </div>
    );
}

export default Sidebar;