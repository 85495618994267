const dotPhrases = {
    //Infectious Diseases and General
    ".nexabdomen": "Abdomen: Bowel sounds present. Soft, non-tender, non-distended.",
    ".nexcard": "CVS: Normal S1 and S2. No murmurs, rubs or gallops appreciated on auscultation.",
    ".nexchest": "Chest: Lungs clear to auscultation bilaterally.",
    ".nexextr": "Extremities: No edema noted to lower extremities.",
    ".nexgen": "General: Appears their stated age, in no acute distress.",
    ".nexheent": "HEENT: No conjunctival injection.",
    ".nexneuro": "Neurologic: CN II-XII grossly intact. 5/5 strength to upper and lower extremities bilaterally. No dysmetria. No dysdiadochokinesia.",
    ".paxlovid": "Nirmatrelvir-Ritonavir",
    ".signoff": "Infectious Diseases to sign off for now, however, please do not hesitate to reach out for any questions, concerns or changes in clinical status.",
};

//Combined physical examination Dotphrase
dotPhrases[".nexphysical"] = [
    dotPhrases[".nexgen"],
    dotPhrases[".nexheent"],
    dotPhrases[".nexcard"],
    dotPhrases[".nexchest"],
    dotPhrases[".nexabdomen"],
    dotPhrases[".nexextr"],
].join("\n");

export default dotPhrases; 