import { useEffect, useCallback, useRef } from 'react';

const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 minutes in milliseconds

export const useInactivityTimer = (onInactive) => {
    const timerRef = useRef(null);
    const isRecordingRef = useRef(false);

    const resetTimer = useCallback(() => {
        if (isRecordingRef.current) return; // Don't set timer if recording
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(onInactive, INACTIVITY_TIMEOUT);
    }, [onInactive]);

    useEffect(() => {
        const handleRecordingStateChange = (event) => {
            isRecordingRef.current = event.detail.isRecording;
            if (isRecordingRef.current) {
                if (timerRef.current) clearTimeout(timerRef.current);
            } else {
                resetTimer();
            }
        };
        
        // Set up activity listeners
        const activityEvents = ['mousedown', 'keydown', 'touchstart', 'mousemove'];
        activityEvents.forEach(event => document.addEventListener(event, resetTimer));
        
        // Initial timer setup
        resetTimer();

        // Cleanup
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            window.removeEventListener('recordingStateChange', handleRecordingStateChange);
            activityEvents.forEach(event => document.removeEventListener(event, resetTimer));
        };
    }, [resetTimer]);

    return resetTimer;
};