import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import NoteEditor from './components/NoteEditor';
import Snackbar from './components/Snackbar'
import DeleteAllConfirmation from './components/DeleteAllConfirmation';
import Login from './components/Login';
import { useNotes } from './hooks/useNotes';
import { useInactivityTimer } from './hooks/useInactivityTimer';
import { exportSignout } from './components/utils/docxExportUtils';
import { useSettings } from './context/SettingsContext';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';

export const presetSections = {
    'ID Consult': ['consultInfo', 'hpi', 'medicalHistory', 'socialHistory', 'antimicrobials', 'physicalExam', 'labs', 'imaging', 'recommendations'],
    'ID Follow Up': ['hpi', 'antimicrobials', 'physicalExam', 'labs', 'imaging', 'recommendations'],
    'Outpatient': ['hpi', 'physicalExam', 'recommendations']
};

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authChecked, setAuthChecked] = useState(false);
    const [authToken, setAuthToken] = useState(null);
    const [collapseHeader, setCollapseHeader] = useState(false);
    const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);

    const {
        notes,
        setNotes,
        currentNote,
        setCurrentNote,
        isEditing,
        error,
        isLoading,
        selectNote,
        createNewNote,
        updateCurrentNoteLocally,
        saveCurrentNote,
        deleteNote,
        deleteAllNotes,
        summarizeNote,
        fetchNotes,
        reorderNotes,
        newNoteCreated,
        changedFields,
        setChangedFields,
        resetInitialFetchDone,
        resetLastViewedNoteId,
    } = useNotes(SERVER_URL, authToken);

    const { loadSettings } = useSettings();

    const handleLogin = useCallback((token, collapseHeaderValue) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('collapseHeader', collapseHeaderValue);
        setIsAuthenticated(true);
        setAuthToken(token);
        setCollapseHeader(collapseHeaderValue);
        fetchNotes();
        loadSettings(); // Load settings after successful login
    }, [fetchNotes, loadSettings]);

    const handleLogout = useCallback(async () => {
        try {
            const response = await fetch(`${SERVER_URL}/api/logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Logout failed');
            }

            setCurrentNote(null);
            setNotes([]);
            setIsPanelCollapsed(true);
            setShowDeleteAllConfirmation(false);
            setIsAuthenticated(false);
            setAuthToken(null);
            setCollapseHeader(true);
            sessionStorage.removeItem('token');
            resetInitialFetchDone();
            resetLastViewedNoteId();
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }, [authToken, setCurrentNote, setNotes, resetInitialFetchDone, resetLastViewedNoteId]);

    const handleInactivity = useCallback(() => {
        console.log('User inactive, logging out');
        handleLogout();
    }, [handleLogout]);

    useInactivityTimer(handleInactivity);

    const handleCreateNewNote = useCallback(async () => {
        const newNote = await createNewNote();
        if (newNote) {
            selectNote(newNote);
        }
    }, [createNewNote, selectNote]);

    const handleUpdateNote = useCallback((field, value) => {
        updateCurrentNoteLocally(field, value);

        if (field === 'title') {
            setNotes(prevNotes => prevNotes.map(note =>
                note.id === currentNote.id ? { ...note, title: value } : note
            ));
        }
    }, [updateCurrentNoteLocally, currentNote, setNotes]);

    const handleSaveNote = useCallback(async () => {
        const success = await saveCurrentNote();
        if (success) {
            console.log('Note saved successfully');
        } else {
            console.log('Failed to save note');
        }
        return success
    }, [saveCurrentNote]);

    const handleDeleteAll = useCallback(async () => {
        await deleteAllNotes();
        setShowDeleteAllConfirmation(false);
    }, [deleteAllNotes]);

    const handleDeleteNote = useCallback(async (noteId) => {
        await deleteNote(noteId);
    }, [deleteNote]);

    const handleSignout = useCallback(() => {
        exportSignout(notes);
    }, [notes]);

    useEffect(() => {
        const checkAuth = () => {
            const token = sessionStorage.getItem('token');
            const savedCollapseHeader = sessionStorage.getItem('collapseHeader');
            if (token) {
                setIsAuthenticated(true);
                setAuthToken(token);
                setCollapseHeader(savedCollapseHeader === 'true');
            }
            setAuthChecked(true);
        };
        checkAuth();
    }, []);

    useEffect(() => {
        if (isAuthenticated && authChecked && authToken) {
            fetchNotes();
        }
    }, [isAuthenticated, authChecked, authToken, fetchNotes]);

    useEffect(() => {
        if (notes.length > 0 && !currentNote) {
            selectNote(notes[0]);
        }
    }, [notes, currentNote, selectNote]);

    if (!authChecked) {
        return (
            <div className="flex flex-col items-center justify-center h-screen bg-[#1e1e1e] text-white">
                <p className="mb-4">Checking authentication...</p>
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Login onLogin={handleLogin} />;
    }

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen bg-[#121212] text-white font-sans">
                <p className="mb-4 text-xl sm:text-2xl font-bold">Loading notes<span className="animate-ellipsis"></span></p>
                <p className="text-sm text-[#ababab]">This may take a few moments</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-screen bg-[#1e1e1e] text-white">
                <p className="text-red-500 mb-4">{error}</p>
                <button
                    onClick={fetchNotes}
                    className="px-4 py-2 bg-[#1976d2] text-white rounded hover:bg-[#1976d2]"
                >
                    Retry
                </button>
            </div>
        );
    }

    return (
        <div className="bg-[#121212] text-gray-100 font-sans min-h-screen">
            <Header onLogout={handleLogout} collapseHeader={collapseHeader} />
            <div className={`pt-${collapseHeader ? '12' : '20'} sm:pt-${collapseHeader ? '16' : '24'}`}>
                <Sidebar
                    isPanelCollapsed={isPanelCollapsed}
                    setIsPanelCollapsed={setIsPanelCollapsed}
                    notes={notes}
                    selectedNote={currentNote}
                    handleNewNote={handleCreateNewNote}
                    handleNoteSelect={selectNote}
                    handleDeleteNote={handleDeleteNote}
                    setShowDeleteAllConfirmation={setShowDeleteAllConfirmation}
                    reorderNotes={reorderNotes}
                    newNoteCreated={newNoteCreated}
                    handleSignout={handleSignout}
                />
                <div className="transition-all duration-300 ml-14 sm:ml-16">
                    <main className="p-4">
                        {currentNote ? (
                            <NoteEditor
                                note={currentNote}
                                updateNote={handleUpdateNote}
                                saveNote={handleSaveNote}
                                isEditing={isEditing}
                                handleNewNote={handleCreateNewNote}
                                summarizeNote={summarizeNote}
                                error={error}
                                changedFields={changedFields}
                                setChangedFields={setChangedFields}
                                authToken={authToken}
                                currentPreset={currentNote.preset || 'ID Consult'}
                                presetSections={presetSections}
                                collapseHeader={collapseHeader}
                            />
                        ) : (
                            <div className="flex items-center justify-center h-[calc(100vh-8rem)] pt-16">
                                <p className="text-[#ababab]">Create a new note from the sidebar.</p>
                            </div>
                        )}
                    </main>
                </div>
            </div>
            {showDeleteAllConfirmation && (
                <DeleteAllConfirmation
                    onConfirm={handleDeleteAll}
                    onCancel={() => setShowDeleteAllConfirmation(false)}
                />
            )}
            <Snackbar/>
        </div>
    );
}

export default App;