import React, { useState } from 'react';
import axios from 'axios';
import loginLogo from '../images/login_logo.png';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [collapseHeader, setCollapseHeader] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        try {
            console.log('Attempting login to:', `${SERVER_URL}/api/login`);
            const res = await axios.post(`${SERVER_URL}/api/login`, { username, password });
            sessionStorage.setItem('token', res.data.token);
            sessionStorage.setItem('collapseHeader', collapseHeader);
            onLogin(res.data.token, collapseHeader);
        } catch (err) {
            console.error('Login error:', err);
            setError(err.response?.data?.error || 'An error occurred during login');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-[#121212] px-4 py-8 font-sans">
            <form onSubmit={handleSubmit} className="bg-[#1e1e1e] p-6 sm:p-8 rounded-lg shadow-md w-full max-w-md border border-[#2a2a2a]">
                <div className="flex flex-col items-center mb-6">
                    <img src={loginLogo} alt="Login Logo" className="w-24 sm:w-32 h-auto mb-4" />
                    <h2 className="text-xl sm:text-2xl font-bold text-white">Login</h2>
                </div>
                {error && <p className="text-red-500 mb-4 text-sm sm:text-base">{error}</p>}
                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-[#e0e0e0] mb-2">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full px-3 py-2 bg-[#242424] border-[#323232] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#1976d2] text-sm sm:text-base border border-[#323232] [text-security:disc] [-webkit-text-security:disc]"
                        disabled={isLoading}
                        autoComplete="off"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-sm font-medium text-[#e0e0e0] mb-2">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 bg-[#242424] border-[#323232] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#1976d2] text-sm sm:text-base border border-[#323232]"
                        disabled={isLoading}
                    />
                </div>
                <div className="flex items-center justify-between mb-4">
                    <label htmlFor="collapseHeader" className="text-xs sm:text-sm font-medium text-[#e0e0e0] mr-2">Collapse Header</label>
                    <div
                        className={`w-10 sm:w-12 h-5 sm:h-6 flex items-center rounded-full p-1 cursor-pointer ${collapseHeader ? 'bg-[#1976d2]' : 'bg-[#2a2a2a]'}`}
                        onClick={() => setCollapseHeader(!collapseHeader)}
                    >
                        <div
                            className={`bg-white w-3 sm:w-4 h-3 sm:h-4 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${collapseHeader ? 'translate-x-5 sm:translate-x-6' : ''}`}
                        ></div>
                    </div>
                </div>
                <button
                    type="submit"
                    className="w-full p-2 bg-[#1976d2] text-white rounded-md hover:bg-blue-700 disabled:opacity-50 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-[#1976d2] focus:ring-offset-2 focus:ring-offset-gray-900 text-sm sm:text-base"
                    disabled={isLoading}
                >
                    <span className="material-icons text-lg sm:text-xl mr-1">login</span>
                    <span className="font-medium">
                        {isLoading ? 'Logging in...' : 'Login'}
                    </span>
                </button>
            </form>
        </div>
    );
}

export default Login;