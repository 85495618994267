import React, { useState, useCallback } from 'react';
import { formatNoteForExport, copyToClipboard } from './utils/noteFormatUtils';
import { useSettings } from '../context/SettingsContext';

const CopyAllButton = ({ note, currentPreset, presetSections }) => {
    const [copied, setCopied] = useState(false);
    const { settings } = useSettings();

    const handleCopyAll = useCallback(async () => {
        const formattedSections = formatNoteForExport(note, currentPreset, presetSections, 'html', settings).join('');

        try {
            await copyToClipboard(formattedSections, true);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }, [note, currentPreset, presetSections, settings]);

    return (
        <button
            onClick={handleCopyAll}
            className="flex items-center justify-center px-3 py-1.5 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] transition duration-150 ease-in-out"
            title="Copy All"
        >
            <span className="material-symbols-outlined text-white text-base mr-1">
                {copied ? 'check_circle' : 'file_copy'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline">Copy All</span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap sm:hidden">Copy</span>
        </button>
    );
};

export default CopyAllButton;