import React from 'react';

function SaveButton({ handleSave, actionSuccess, hasUnsavedChanges }) {
    return (
        <div className="fixed bottom-4 right-4 z-10">
            <button
                type="button"
                onClick={handleSave}
                className={`flex flex-col items-center justify-center p-2 sm:p-3 rounded-lg ${
                    actionSuccess.save === true
                        ? 'bg-green-600 hover:bg-green-700'
                        : actionSuccess.save === 'error'
                        ? 'bg-red-600 hover:bg-red-700'
                        : hasUnsavedChanges
                        ? 'bg-blue-400 hover:bg-[#1976d2]'
                        : 'bg-[#242424] hover:bg-[#2a2a2a]'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] transition duration-150 ease-in-out shadow-lg border-2 ${
                    hasUnsavedChanges ? 'border-blue-300' : 'border-[#323232]'
                }`}
                title="Save"
                disabled={actionSuccess.save === 'saving'}
            >
                <span className="material-icons text-white text-2xl">
                    {actionSuccess.save === true
                        ? 'check_circle'
                        : actionSuccess.save === 'error'
                        ? 'error'
                        : actionSuccess.save === 'saving'
                        ? 'hourglass_empty'
                        : hasUnsavedChanges
                        ? 'save_as'
                        : 'save'}
                </span>
                <span className="text-xs text-white whitespace-nowrap hidden sm:inline mt-1">
                    {actionSuccess.save === true
                        ? 'Saved!'
                        : actionSuccess.save === 'error'
                        ? 'Error'
                        : actionSuccess.save === 'saving'
                        ? 'Saving...'
                        : 'Save'}
                </span>
            </button>
        </div>
    );
}

export default SaveButton;