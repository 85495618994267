import React, { useState, useEffect, useRef } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import phraseList from './data/phraseList.json';

const AudioTranscription = ({ 
    onTranscriptionUpdate, 
    onRecordingStart, 
    onRecordingEnd,
    isActiveSection,
    buttonClassName
}) => {
    const [isRecording, setIsRecording] = useState(false);
    const recognizerRef = useRef(null);
    const wakeLockRef = useRef(null);
    const currentTranscriptRef = useRef('');

    useEffect(() => {
        return () => {
            if (recognizerRef.current) {
                recognizerRef.current.close();
            }
        };
    }, []);

    //These WakeLock functions help to prevent the phone screen from falling asleep.
    const acquireWakeLock = async () => {
        if ('wakeLock' in navigator) {
            try {
                wakeLockRef.current = await navigator.wakeLock.request('screen');
                console.log('Wake Lock acquired');
            } catch (err) {
                console.error('Failed to acquire Wake Lock:', err);
            }
        }
    };

    const releaseWakeLock = () => {
        if (wakeLockRef.current) {
            wakeLockRef.current.release()
                .then(() => {
                    console.log('Wake Lock released');
                    wakeLockRef.current = null;
                })
                .catch((err) => {
                    console.error('Failed to release Wake Lock:', err);
                });
        }
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            
            const audioConfig = sdk.AudioConfig.fromStreamInput(stream);
            const speechConfig = sdk.SpeechConfig.fromSubscription(
                process.env.REACT_APP_AZURE_SPEECH_KEY,
                process.env.REACT_APP_AZURE_SPEECH_REGION
            );
            
            // Enable dictation mode
            speechConfig.enableDictation();

            // Disable automatic punctuation
            speechConfig.setServiceProperty(
                "punctuation", "explicit",
                sdk.ServicePropertyChannel.UriQueryParameter
            );

            recognizerRef.current = new sdk.SpeechRecognizer(speechConfig, audioConfig);

            const phraseListGrammar = sdk.PhraseListGrammar.fromRecognizer(recognizerRef.current);
            phraseList.forEach(phrase => {
                phraseListGrammar.addPhrase(phrase);
            });

            recognizerRef.current.recognized = (s, e) => {
                if (e?.result?.reason === sdk.ResultReason.RecognizedSpeech) {
                    const newText = e?.result?.text;
                    if (typeof newText === 'string' && newText.trim()) {
                        onTranscriptionUpdate(newText.trim(), true);
                    }
                }
            };

            recognizerRef.current.startContinuousRecognitionAsync(() => {
                currentTranscriptRef.current = '';
                setIsRecording(true);
                onRecordingStart();
                acquireWakeLock();
            });

        } catch (error) {
            console.error('Error starting recording:', error);
            setIsRecording(false);
        }
    };

    const stopRecording = () => {
        if (recognizerRef.current) {
            try {
                recognizerRef.current.stopContinuousRecognitionAsync(
                    () => {
                        setIsRecording(false);
                        onRecordingEnd();
                        releaseWakeLock();
                    },
                    (err) => {
                        console.error('Error stopping recognition:', err);
                        setIsRecording(false);
                        onRecordingEnd();
                        releaseWakeLock();
                    }
                );
            } catch (error) {
                console.error('Error in stopRecording:', error);
                setIsRecording(false);
                onRecordingEnd();
                releaseWakeLock();
            }
        }
    };

    const toggleRecording = (e) => {
        // Prevent the button click from causing the textarea to lose focus
        e.preventDefault();
        e.stopPropagation();
        
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    return (
        <button
            onClick={toggleRecording}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            className={`${buttonClassName} transform transition-all duration-300 ease-in-out ${
                !isActiveSection && !isRecording 
                    ? 'scale-75 opacity-0 -translate-y-2 pointer-events-none absolute' 
                    : 'scale-100 opacity-100 translate-y-0'
            } ${
                isRecording
                    ? 'bg-red-600 hover:bg-red-700 border-red-500'
                    : 'bg-[#2a2a2a] hover:bg-[#323232]'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                isRecording ? 'focus:ring-red-500' : 'focus:ring-[#1976d2]'
            }`}
            title={isRecording ? "Stop Recording" : "Start Recording"}
        >
            <span className={`material-icons text-white text-base`}>
                {isRecording ? 'stop' : 'mic'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                {isRecording ? 'Stop' : 'Record'}
            </span>
        </button>
    );
};

export default AudioTranscription;