import React, { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

const fetchCodeDescription = async (code) => {
    try {
        const response = await fetch(
            `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${encodeURIComponent(code)}`
        );
        const [, , , data] = await response.json();
        return data.find(([c]) => c === code)?.[1] || '';
    } catch (error) {
        console.error('Error fetching ICD-10 description:', error);
        return '';
    }
};

const CodeDescription = ({ code }) => {
    const [descriptions, setDescriptions] = useState([]);
    
    useEffect(() => {
        const codes = code.split(',').map(c => c.trim());
        Promise.all(codes.map(fetchCodeDescription))
            .then(descs => {
                setDescriptions(codes.map((c, i) => ({
                    code: c,
                    description: descs[i]
                })));
            });
    }, [code]);

    return descriptions.length ? (
        <div className="text-sm text-[#ababab] italic ml-4 space-y-1">
            {descriptions.map(({ code, description }) => (
                <div key={code}>
                    {code} - {description}
                </div>
            ))}
        </div>
    ) : null;
};

function BillingButton({ assessment, updateNote }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [activeSearch, setActiveSearch] = useState(null);
    const [editingDiagnosis, setEditingDiagnosis] = useState(null);
    const [selectedCodeDetails, setSelectedCodeDetails] = useState({});
    const selectionRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectionRef.current && !selectionRef.current.contains(event.target)) {
                setEditingDiagnosis(null);
                setActiveSearch(null);
                setSearchResults([]);
                setSearchTerm('');
            }
        }

        if (editingDiagnosis) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [editingDiagnosis]);

    const findDiagnoses = (text) => {
        const regex = /#(.*?)(?:\(([A-Z][0-9.]+(?:\s*,\s*[A-Z][0-9.]+)*)\)|(?=\n|$))/g;
        const matches = [...text.matchAll(regex)];
        return matches.map(match => ({
            diagnosis: match[1].trim(),
            codes: match[2]?.split(',').map(code => code.trim()) || [],
            position: match.index,
            length: match[0].length
        }));
    };

    const searchICD10 = async (term) => {
        if (!term) {
            setSearchResults([]);
            return;
        }
        try {
            const response = await fetch(
                `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${encodeURIComponent(term)}&maxList=200`
            );
            const [, , , data] = await response.json(); //If needed, use total, codes as first two parameters
            const formattedResults = data.map(([code, name]) => ({
                code,
                name
            }));
            setSearchResults(formattedResults);
        } catch (error) {
            console.error('Error fetching ICD-10 codes:', error);
        }
    };

    const toggleICDCode = (code, name) => {
        if (!activeSearch) return;

        if (!activeSearch.codes.includes(code)) {
            setSelectedCodeDetails(prev => ({
                ...prev,
                [code]: name
            }));
        }

        const codes = activeSearch.codes.includes(code)
            ? activeSearch.codes.filter(c => c !== code)
            : [...activeSearch.codes, code];
        
        const beforeText = assessment.substring(0, activeSearch.position);
        const afterText = assessment.substring(activeSearch.position + activeSearch.length);
        const diagnosisWithCode = codes.length > 0 
            ? `#${activeSearch.diagnosis} (${codes.join(', ')})`
            : `#${activeSearch.diagnosis}`;
        
        const updatedText = beforeText + diagnosisWithCode + afterText;
        updateNote("recommendations", updatedText);
        
        // Update activeSearch with new codes
        setActiveSearch({
            ...activeSearch,
            codes,
            length: diagnosisWithCode.length
        });
    };

    const diagnoses = findDiagnoses(assessment);

    const debouncedSearch = useMemo(
        () => debounce((term) => {
            searchICD10(term);
        }, 200),
        []
    );

    return (
        <>
            <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] transition duration-150 ease-in-out border border-[#323232]"
                title="Insert ICD-10 Codes"
            >
                <span className="material-icons text-white text-base">receipt_long</span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                    ICD-10
                </span>
            </button>

            {isModalOpen && (
                <div className="fixed inset-0 bg-[#121212] bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-[#1e1e1e] p-4 sm:p-6 rounded-lg shadow-xl border border-[#323232] w-full max-w-2xl relative">
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="absolute top-2 right-2 text-[#ababab] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2]"
                            aria-label="Close"
                        >
                            <span className="material-icons">close</span>
                        </button>
                        
                        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-100 text-center">
                            Insert ICD-10 Codes
                        </h2>

                        <div className="mb-4">
                            <div className="space-y-6">
                                {diagnoses.map((diag, index) => (
                                    <div key={index} 
                                        className="flex flex-col gap-2 p-4 bg-[#242424]/50 rounded-lg border border-[#323232]">
                                        <div className="flex flex-wrap items-center gap-2">
                                            <span className="text-white font-medium break-all">{diag.diagnosis}</span>
                                            {editingDiagnosis?.diagnosis !== diag.diagnosis && (
                                                <button
                                                    onClick={async () => {
                                                        setActiveSearch(diag);
                                                        setEditingDiagnosis(diag);
                                                        setSearchTerm('');
                                                        searchICD10('');
                                                        
                                                        // Load descriptions for existing codes
                                                        if (diag.codes.length > 0) {
                                                            const descriptions = await Promise.all(diag.codes.map(fetchCodeDescription));
                                                            const codeDetails = {};
                                                            diag.codes.forEach((code, index) => {
                                                                codeDetails[code] = descriptions[index];
                                                            });
                                                            setSelectedCodeDetails(prev => ({
                                                                ...prev,
                                                                ...codeDetails
                                                            }));
                                                        }
                                                    }}
                                                    className="px-2 py-1 text-xs bg-[#1976d2] hover:bg-[#2196f3] text-white rounded shrink-0"
                                                >
                                                    {diag.codes.length > 0 ? 'Change Codes' : 'Add Codes'}
                                                </button>
                                            )}
                                        </div>
                                        
                                        {editingDiagnosis?.diagnosis === diag.diagnosis ? (
                                            <div className="ml-4" ref={selectionRef}>
                                                <div className="flex flex-col gap-2">
                                                    {/* Show current selections at the top */}
                                                    {activeSearch.codes.length > 0 && (
                                                        <div className="p-2 bg-[#2a2a2a] rounded">
                                                            <div className="text-xs text-[#ababab] mb-1">Current Selections:</div>
                                                            <div className="flex flex-wrap gap-2">
                                                                {activeSearch.codes.map(code => (
                                                                    <div 
                                                                        key={code} 
                                                                        className="flex items-center gap-1 bg-[#323232] px-2 py-1 rounded text-sm"
                                                                    >
                                                                        <div className="flex flex-col">
                                                                            <span className="text-white">{code}</span>
                                                                            <span className="text-xs text-[#ababab]">{selectedCodeDetails[code]}</span>
                                                                        </div>
                                                                        <button
                                                                            onClick={() => toggleICDCode(code)}
                                                                            className="text-[#ababab] hover:text-white ml-1"
                                                                        >
                                                                            <span className="material-icons text-sm">close</span>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    
                                                    {/* Search interface */}
                                                    <div className="flex items-center gap-2">
                                                        <div className="relative flex-1">
                                                            <input
                                                                type="text"
                                                                value={searchTerm}
                                                                onChange={(e) => {
                                                                    setSearchTerm(e.target.value);
                                                                    debouncedSearch(e.target.value);
                                                                }}
                                                                placeholder="Search ICD-10 codes..."
                                                                className="w-full p-2 bg-[#2a2a2a] text-white rounded text-sm"
                                                                autoFocus
                                                            />
                                                            {searchResults.length > 0 && (
                                                                <div className="absolute top-full left-0 right-0 mt-1 max-h-40 overflow-y-auto bg-[#2a2a2a] rounded shadow-lg z-10">
                                                                    {searchResults.map((result) => (
                                                                        <div
                                                                            key={result.code}
                                                                            className="p-2 hover:bg-[#323232] text-white text-sm cursor-pointer"
                                                                            onClick={() => toggleICDCode(result.code, result.name)}
                                                                        >
                                                                            <span>{result.code} - {result.name}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <button
                                                            onClick={() => {
                                                                setEditingDiagnosis(null);
                                                                setActiveSearch(null);
                                                                setSearchResults([]);
                                                                setSearchTerm('');
                                                            }}
                                                            className="px-3 py-1.5 text-sm bg-[#2a2a2a] hover:bg-[#323232] text-white rounded border border-[#323232]"
                                                        >
                                                            Done
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setEditingDiagnosis(null);
                                                                setActiveSearch(null);
                                                                setSearchResults([]);
                                                                setSearchTerm('');
                                                            }}
                                                            className="p-1.5 text-[#ababab] hover:text-white rounded hover:bg-[#2a2a2a]"
                                                        >
                                                            <span className="material-icons text-sm">close</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : diag.codes.length > 0 && (
                                            <CodeDescription code={diag.codes.join(', ')} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center px-4 py-2 rounded-md border border-[#323232] bg-[#2a2a2a] hover:bg-[#323232] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] transition duration-150 ease-in-out text-[#e0e0e0]"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BillingButton;  