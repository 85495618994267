import React, { useMemo, useState } from 'react';
import { BETA_LACTAMS, CROSS_REACTIVITY } from '../data/betaLactamData';

const BetaLactamAllergyView = ({ note }) => {
  const [selectedDrugs, setSelectedDrugs] = useState({});
  
  const allergies = useMemo(() => {
    const allergySection = note.medicalHistory || '';
    const allergyRegex = /(?:Antimicrobial Allergies:|Allergies:)?\s*([\w\s-]+)\s*-\s*([\w\s]+)(?:;|$)/gi;
    const matches = [...allergySection.matchAll(allergyRegex)];
    
    // Filter to only include beta lactam allergies
    const allBetaLactams = Object.values(BETA_LACTAMS).flat();
    return matches
      .map(match => ({
        drug: match[1].trim(),
        reaction: match[2].trim()
      }))
      .filter(allergy => 
        allBetaLactams.some(drug => 
          drug.toLowerCase() === allergy.drug.toLowerCase()
        )
      );
  }, [note]);

  // Return null if no beta lactam allergies found
  if (allergies.length === 0) return null;

  const handleDrugSelect = (allergyDrug, selectedDrug) => {
    setSelectedDrugs(prev => ({
      ...prev,
      [allergyDrug]: selectedDrug
    }));
  };

  const getCrossReactivityStatus = (allergyDrug, selectedDrug) => {
    if (!selectedDrug) return null;
    return CROSS_REACTIVITY[selectedDrug]?.includes(allergyDrug) ? 
      'Shared' : 'None Shared';
  };

  return (
    <div className="space-y-6">
      <h2 className="text-l font-semibold text-gray-100">Check Side Chain Similarities</h2>
      <div className="bg-[#2a2a2a] border border-[#323232] p-4 rounded-lg">
        {allergies.length > 0 ? (
          allergies.map((allergy, index) => (
            <div key={index} className="mb-4 p-3 bg-[#2a2a2a] rounded">
              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-between">
                  <div className="text-white">
                    <span className="font-medium">{allergy.drug}</span>
                    <span className="text-[#ffffff] ml-2">({allergy.reaction.charAt(0).toUpperCase() + allergy.reaction.slice(1)})</span>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <select 
                    className="bg-[#242424] border border-[#323232] text-white rounded p-2 flex-grow"
                    onChange={(e) => handleDrugSelect(allergy.drug, e.target.value)}
                    value={selectedDrugs[allergy.drug] || ''}
                  >
                    <option value="">Select Beta-lactam...</option>
                    {Object.entries(BETA_LACTAMS).map(([group, drugs]) => (
                      <optgroup key={group} label={group.replace(/([A-Z])/g, ' $1').trim()}>
                        {drugs
                          .filter(drug => drug.toLowerCase() !== allergy.drug.toLowerCase())
                          .map(drug => (
                            <option key={drug} value={drug}>{drug}</option>
                          ))
                        }
                      </optgroup>
                    ))}
                  </select>
                  {selectedDrugs[allergy.drug] && (
                    <span className={`px-3 py-1 rounded text-sm ${
                      getCrossReactivityStatus(allergy.drug, selectedDrugs[allergy.drug]) === 'Shared' 
                        ? 'bg-red-500 text-white' 
                        : 'bg-green-500 text-white'
                    }`}>
                      {getCrossReactivityStatus(allergy.drug, selectedDrugs[allergy.drug])}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-[#ababab]">No beta-lactam allergies found in medical history</p>
        )}
      </div>
    </div>
  );
};

export default React.memo(BetaLactamAllergyView); 