export const BETA_LACTAMS = {
  Penicillins: ['Amoxicillin', 'Ampicillin', 'Penicillin', 'Piperacillin'],
  '1st Generation Cephalosporins': ['Cefazolin', 'Cephalexin', 'Cefadroxil'],
  '2nd Generation Cephalosporins': ['Cefaclor', 'Cefotetan', 'Cefoxitin', 'Cefuroxime'],
  '3rd Generation Cephalosporins': ['Ceftriaxone', 'Cefdinir', 'Cefixime', 'Cefotaxime', 'Cefpodoxime', 'Cefprozil', 'Ceftazidime'],
  '4th Generation Cephalosporins': ['Cefepime'],
  Monobactam: ['Aztreonam']
};

// Complete side-chain cross-reactivity mapping
export const CROSS_REACTIVITY = {
  // Penicillins
  'Amoxicillin': ['Ampicillin', 'Penicillin', 'Piperacillin', 'Cephalexin', 'Cefadroxil', 'Cefaclor', 'Cefprozil'],
  'Ampicillin': ['Amoxicillin', 'Penicillin', 'Piperacillin', 'Cephalexin', 'Cefadroxil', 'Cefaclor', 'Cefprozil'],
  'Penicillin': ['Amoxicillin', 'Ampicillin', 'Piperacillin', 'Cefoxitin'],
  'Piperacillin': ['Amoxicillin', 'Ampicillin', 'Penicillin'],
  
  // 1st Generation Cephalosporins
  'Cefazolin': [],
  'Cephalexin': ['Amoxicillin', 'Ampicillin', 'Cefadroxil', 'Cefaclor', 'Cefprozil'],
  'Cefadroxil': ['Amoxicillin', 'Ampicillin', 'Cephalexin', 'Cefaclor', 'Cefprozil'],
  
  // 2nd Generation Cephalosporins
  'Cefaclor': ['Amoxicillin', 'Ampicillin', 'Cephalexin', 'Cefadroxil', 'Cefprozil'],
  'Cefotetan': [],
  'Cefoxitin': ['Penicillin', 'Cefuroxime'],
  'Cefuroxime': ['Cefoxitin'],
  
  // 3rd Generation Cephalosporins
  'Ceftriaxone': ['Cefotaxime', 'Cefpodoxime', 'Cefepime'],
  'Cefdinir': ['Cefixime'],
  'Cefixime': ['Cefdinir'],
  'Cefotaxime': ['Ceftriaxone', 'Cefpodoxime', 'Cefepime'],
  'Cefpodoxime': ['Ceftriaxone', 'Cefotaxime', 'Cefepime'],
  'Cefprozil': ['Amoxicillin', 'Ampicillin', 'Cephalexin', 'Cefadroxil', 'Cefaclor'],
  'Ceftazidime': ['Aztreonam'],

  // 4th Generation Cephalosporins
  'Cefepime': ['Ceftriaxone', 'Cefotaxime', 'Cefpodoxime'],
  
  // Monobactam
  'Aztreonam': ['Ceftazidime']
}; 