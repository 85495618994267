import React, { useState, useEffect } from 'react';

let showSnackbar = () => { };

const Snackbar = () => {
    const [snackbar, setSnackbar] = useState({ open: false, message: '', isError: false });

    useEffect(() => {
        showSnackbar = (message, isError = false, duration = 3000) => {
            setSnackbar({ open: true, message, isError });
            setTimeout(() => setSnackbar({ open: false, message: '', isError: false }), duration);
        };
    }, []);

    if (!snackbar.open) return null;

    const style = {
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: snackbar.isError ? '#d32f2f' : '#323232',
        color: 'white',
        padding: '14px 16px',
        borderRadius: '4px',
        boxShadow: '0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)',
        zIndex: 1000,
    };

    return <div style={style}>{snackbar.message}</div>;
};

export default Snackbar;
export const snackbarMessage = (text, duration) => showSnackbar(text, false, duration);
export const snackbarError = (text, duration) => showSnackbar(text, true, duration);